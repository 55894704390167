import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Layout from '../components/Layout';

import { contentfulRenderOptions } from 'utilities/functions.tsx';

import blogStyles from '../pages/blog.module.scss';

export default ({ data }) => {
  const post = get(data, 'contentfulBlogPost');
  const { siteMetadata } = data.site;

  return (
    <Layout>
      <Helmet>
        <title>{post.title + ' - ' + siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadata.baseUrl + '/blog' + post.slug}/>
        {post.description && (
          <meta name="description" content={post.description}/>
        )}
      </Helmet>
      <Grid container className={blogStyles.container + " " + blogStyles.post}>
        <Grid item lg={10} style={{ margin: '25px auto' }}>
          <Typography variant="h4" className={blogStyles.blog__postTitle}>{post.title}</Typography>
          <div className={blogStyles.authorContainer}>
            <img
              src={post.author.image.file.url}
              alt={post.author.name}
              className={blogStyles.picture}
              height="48"
              width="48"
            />
            <div className={blogStyles.infoBlock}>
              <Typography variant="caption">{post.author.name}</Typography>
              <Typography variant="caption">{post.createdAt} - {post.readTime} min read</Typography>
            </div>
          </div>
          <Typography variant="body2"
            className={blogStyles.body}
            component="div"
          >
            {documentToReactComponents(post.copy.json, contentfulRenderOptions)}
          </Typography>
          <div className={blogStyles.authorContainer}>
            <img
              src={post.author.image.file.url}
              alt={post.author.name}
              className={blogStyles.picture}
              height="72"
              width="72"
            />
            <div className={blogStyles.infoBlock}>
              <Typography variant="caption">Written by</Typography>
              <Typography variant="caption">{post.author.name}</Typography>
              <Typography variant="caption">{post.author.title}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      createdAt(formatString: "MMMM Do, YYYY")
      description
      copy {
        json
      }
      author {
        name
        title
        image {
          file {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        baseUrl
      }
    }
  }
`;